import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../domain/theme';
import { t } from '../domain/services/configService';
import { ImageSimple } from './Image/ImageSimple';
import { imageResizerService } from '../domain/services/imageResizerService';
import { SmartLink } from './SmartLink';

export const Branded = ({ logo, link, fontColor }) => {
  const styles = {
    main: css`
      position: relative;
      ${theme.font.family('regular')};
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 3px;
      color: ${fontColor};
      a {
        color: inherit;
      }
      text-align: center;
      text-transform: uppercase;
      max-width: 1160px;
      margin: 0 auto;
      img {
        vertical-align: middle;
      }
    `,
    text: css`
      margin-right: 16px;
    `
  };

  const imageWidth = imageResizerService.getWidth(logo.entity.mediaImage, 40);
  const inner = (
    <>
      <span css={styles.text}>{t('Sponsored by')}</span>
      <ImageSimple
        src={imageResizerService.getStyleUrl(
          logo.entity.mediaImage.url,
          null,
          40,
          { position: logo.entity.focalPoint }
        )}
        width={imageWidth}
        height={40}
        alt=""
      />
    </>
  );

  return (
    <p css={styles.main}>
      {link ? (
        <SmartLink to={link} rel="sponsored" target="_blank">
          {inner}
        </SmartLink>
      ) : (
        inner
      )}
    </p>
  );
};

Branded.defaultProps = {
  link: undefined,
  fontColor: 'black'
};

Branded.propTypes = {
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
  link: PropTypes.string,
  fontColor: PropTypes.string
};
